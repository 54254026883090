import React from "react"
import Complaints from "../../../components/body/pages/en-gb/legal/complaints"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

const ComplaintPage = () => (
  <Layout>
    <SEO
      canonical={"https://kuda.com/en-gb/legal/complaints-policy/"}
      title="Complaints Policy | Kuda | The Money App for Africans"
    />
    <Complaints />
  </Layout>
)

export default ComplaintPage
