import { Link } from "gatsby"
import React, { useEffect, Fragment } from "react"
import { scrollToElement } from "../../../../../utility/utils"
import CTAUk from "../../../general/ctaUk"

const Complaints = () => {
    useEffect(() => {
        scrollToElement()
        window.addEventListener("scroll", function () {
            scrollToElement()
        })
    }, [])
    return (
        <Fragment>
            <div className="kuda-inner--heading kuda-disclaimer--section">
                <div className="kuda-section--inner general-style">
                    <div className="kuda-section--100 inner-spotlight--heading pad-bottom text-center">
                        <Link to="/en-gb/legal/" className="color-black mb-3 mt-4 back-page">
                            <div className="align-self-center mr-2">
                                <div className="f-14">
                                    Legal
                                </div>
                            </div>
                            <span className="mr-2">
                                <svg width={8} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M0.0631554 1.92842L1.48887 0.5L7.99558 6.99436L1.48952 13.5L0.0625 12.0729L5.14004 6.99567L0.0631554 1.92842Z"
                                        fill="#C7C7CC"
                                    ></path>
                                </svg>
                            </span>
                            <span className="align-self-center f-14 mr-2 text-left">United Kingdom</span>
                            <span className="mr-2">
                                <svg width={8} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M0.0631554 1.92842L1.48887 0.5L7.99558 6.99436L1.48952 13.5L0.0625 12.0729L5.14004 6.99567L0.0631554 1.92842Z"
                                        fill="#C7C7CC"
                                    ></path>
                                </svg>
                            </span>
                            <span className="align-self-center f-14 text-bold text-left">Complaints</span>
                        </Link>
                        <div className="legal-left">
                            <h1 className="kuda-section--heading text-lg-biz text-xlbold color-primary title-bottom--spacing no-margins">
                                Complaints
                            </h1>
                        </div>
                    </div>

                    {/* <div className="kuda-section--100 kuda-spacing--inner">
                        <h1 className="kuda-section--heading text-left text-xlbold color-primary title-bottom--spacing">
                            Customer Complaints
                        </h1>
                        <div className="text-left kuda-disclaimer--wrap">
                            <p className="color-black bottom--spacing">
                                Kuda EMI Limited (“Kuda UK”) is committed to the reasonable, fair and prompt handling of {" "}
                                <a
                                    href="https://www.handbook.fca.org.uk/handbook/glossary/G197.html"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="color-primary text-bold link-decoration cursor-pointer"
                                >
                                    complaints
                                </a>
                            </p>
                        </div>
                    </div> */}

                    <div className="kuda-section--100 kuda-spacing--inner">
                        {/* <h1 className="kuda-section--heading text-left text-xlbold color-primary title-bottom--spacing">
                            Introduction
                        </h1> */}
                        {/* <div className="text-left kuda-disclaimer--wrap">
                            <p className="color-black bottom--spacing">
                                A complaint is any expression of dissatisfaction whether oral or written and whether justified or not from or on behalf of a complainant about Kuda UK provision of or failure to provide a specific financial service which;
                            </p>
                            <p className="color-black bottom--spacing">
                                <ul className="diclaimer-list">
                                    <li>
                                        alleges that the complainant has suffered (or may suffer) financial loss, material distress or material inconvenience; and
                                    </li>
                                    <li>relates to an activity of that respondent, or of any other respondent with whom that respondent has some connection in marketing or providing financial services or products, which comes under the jurisdiction of the Financial Ombudsman Service (FOS).</li>
                                </ul>
                            </p>
                            <p className="color-black bottom--spacing">
                                All Kuda UK employees who might potentially interact with a customer must be aware of how to deal with a possible complaint appropriately and quickly. The FCA requires us to have documented, appropriate, effective internal complaint handling procedures. The procedures to be followed are set out below.
                            </p>

                            <h1 className="kuda-section--heading text-left text-xlbold color-primary title-bottom--spacing">
                                Complainants
                            </h1>
                            <div className="text-left kuda-disclaimer--wrap">
                                <p className="color-black bottom--spacing">
                                    A complainant must be a person that is defined under {" "}
                                    <a
                                        href="https://www.handbook.fca.org.uk/handbook/DISP/2/7.html"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="color-primary text-bold link-decoration cursor-pointer"
                                    >
                                        DISP 2.7
                                    </a>. Kuda UK will refer to this each time a complaint is received as the default position if there is any doubt of definition.
                                </p>
                                <p className="color-black bottom--spacing">
                                    An complainant is:
                                </p>
                                <p className="color-black bottom--spacing">
                                    <ul className="diclaimer-list">
                                        <li>
                                            A private individual (consumer)
                                        </li>
                                        <li>
                                            A micro-enterprise, which is a small business with less than 10 employees and an annual turnover of less than £2m;
                                        </li>
                                        <li>
                                            A charity with an annual income of less than £6.5m;
                                        </li>
                                        <li>
                                            A trustee of a trust which has net asset value of less than £5m; Or
                                        </li>
                                        <li>
                                            A small business which is not a micro- enterprise, has a turnover of less than £6.5m and employs less than 50 people or has a balance sheet of less than £5m.
                                        </li>
                                        <li>
                                            Certain third parties or persons acting on behalf of an complainant.
                                        </li>
                                    </ul>
                                </p>
                                <p className="color-black bottom--spacing">
                                    If any complainant complaints are received, this will incur reporting in accordance with DISP 1.10.
                                </p>
                            </div>

                            <h1 className="kuda-section--heading text-left text-xlbold color-primary title-bottom--spacing">
                                Complaints Procedure
                            </h1>
                            <div className="text-left kuda-disclaimer--wrap">
                                <p className="color-black bottom--spacing">
                                    The FCA has issued specific rules governing the manner in which regulated firms like Kuda UK are to handle customer complaints.
                                </p>
                                <p className="color-black bottom--spacing">
                                    The FCA’s rules relating to complaints are designed to lay down minimum standards for the proper handling and resolution of complaints received from customers.
                                </p>
                                <p className="color-black bottom--spacing">
                                    This is to ensure that complaints are handled fairly, effectively and promptly, and resolved at the earliest possible opportunity, minimising the number of unresolved complaints which need to be referred to the FOS. This purpose is consistent with the FCA’s consumer protection regulatory objective. Kuda UK are expected to:
                                </p>
                                <p className="color-black bottom--spacing">
                                    <ul className="diclaimer-list">
                                        <li>Acknowledge complaints promptly; </li>
                                        <li>Make an objective, prompt and thorough initial investigation of complaints;</li>
                                        <li>Provide a timely reply to the complainant after the initial investigation;</li>
                                        <li>Inform the complainant of his/her right to apply to the FOS; and</li>
                                        <li>Keep adequate records of complaints and investigations.</li>
                                    </ul>
                                </p>
                                <p className="color-black bottom--spacing">
                                    Once identified, complaints must be immediately referred to the appropriate line manager as well as to the Compliance Manager at the email address {" "}
                                    <a
                                        href="mailto:complaintuk@kuda.com"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="color-primary text-bold link-decoration cursor-pointer"
                                    >
                                        complaintuk@kuda.com
                                    </a>  Once received, complaints will be entered in the complaints log and the progress to resolution appropriately recorded.
                                </p>
                            </div>

                            <h1 className="kuda-section--heading text-left text-xlbold color-primary title-bottom--spacing">
                                Complaint Handling
                            </h1>
                            <div className="text-left kuda-disclaimer--wrap">
                                <p className="color-black bottom--spacing">
                                    The complaints procedure must be followed for all customers
                                </p>
                                <p className="color-black bottom--spacing">
                                    Kuda UK complaints procedures are designed to ensure that:
                                </p>
                                <p className="color-black bottom--spacing">
                                    <ul className="diclaimer-list">
                                        <li>
                                            Complaints from customers are investigated promptly and fully by a member of staff who is independent, competent and detached from the matter(s) giving rise to the complaint.
                                        </li>
                                        <li>Any appropriate remedial action is promptly taken.</li>
                                    </ul>
                                </p>
                                <p className="color-black bottom--spacing">
                                    Where the complaint is not resolved to the customer's satisfaction, complainants are advised of their further right of complaint to the FOS. Kuda UK complaints procedure can be found on the company website at {" "}
                                    <a
                                        href="https://kuda.com/en-gb/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="color-primary text-bold link-decoration cursor-pointer"
                                    >
                                        kuda.com/en-gb
                                    </a>
                                </p>
                            </div>
                        </div> */}

                        {/* <div className="kuda-section--100 kuda-spacing--inner">
                            <h1 className="kuda-section--heading text-left text-xlbold color-primary title-bottom--spacing">
                                Internal Complaints Procedure
                            </h1>
                            <div className="text-left kuda-disclaimer--wrap">
                                <p className="color-black bottom--spacing">
                                    The Compliance Manager is responsible for maintaining and ensuring this register is up to date and accurate. This is to ensure regulations on reporting complaints are adhered to in the annual report made to the FCA on complaint statistics
                                </p>
                                <p className="color-black bottom--spacing">
                                    Previously complaints that were allowed a casual response to complaints resolved before the end of the next business days were exempt, but this exemption has been removed. However, with effect from 25th October 2015, in accordance with DISP 1.5, firms are permitted to handle complaints less formally, without sending a final response letter, by the close of three business days after the date of receipt.
                                </p>
                                <p className="color-black bottom--spacing">
                                    Even if the complaint is resolved by the close of the third business day after receipt, raising consumer awareness of the FOS, by sending a ‘summary resolution communication’ following the resolution should be sent out.
                                </p>
                                <p className="color-black bottom--spacing">
                                    If a complaint remains unresolved by the end of the three working days, an acknowledgement should be sent promptly to the customer by email or letter, notifying them who the investigating person is, the position of that person and that the allegations will be handled fully and impartially. The notification should also advise the customer of the company “Internal Complaints Procedure”. (This is the internal for public see appendix 1)
                                </p>
                                <p className="color-black bottom--spacing">
                                    In accordance with DISP 1.6.2AR, a final written reply setting out the findings will be sent to the complainant within 15 working days of the original date of receipt of complaint or a holding response which will detail why the complaint is requiring extra time to be resolved. Any final response sent must be on paper, or if agreed between the respondent and the complainant, on another durable medium.
                                </p>
                                <p className="color-black bottom--spacing">
                                    If a final response cannot be given, the Investigating person must:
                                </p>
                                <p className="color-black bottom--spacing">
                                    <ul className="diclaimer-list">
                                        <li>
                                            Send a holding response to the complainant by the end of 15 business days after the day on which it received the complaint, clearly indicating the reasons for the delay in answering the complaint and specifying the deadline by which it will send the final response; and
                                        </li>
                                        <li>Send a final response to the complainant by the end of 35 business days after the day on which it received the complaint. Any final response sent must be on paper, or if agreed between the respondent and the complainant, on another durable medium.</li>
                                    </ul>
                                </p>
                                <p className="color-black bottom--spacing">
                                    Once the complaint has been investigated to the standard Kuda UK is satisfied with, the findings should be reported back to the customer and the response retained. The final response letter sent to the customer should give the address and other contact details of the FOS, so that in the event that the customer is unsatisfied with the resolution of the complaint they have the opportunity to pursue the complaint via the FOS scheme within six months of the date to the final correspondence received from Kuda UK. For information on the FOS please see:
                                    {" "}
                                    <a
                                        href="https://www.financial-ombudsman.org.uk"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="color-primary text-bold link-decoration cursor-pointer"
                                    >
                                        https://www.financial-ombudsman.org.uk/
                                    </a> .
                                </p>
                                <p className="color-black bottom--spacing">
                                    <span className="text-bold">Note:</span> The FOS will not consider a case which has not first been referred to Kuda UK in the first instance.
                                </p>
                                <p className="color-black bottom--spacing">
                                    All complaints, whether significant or minor, are to be maintained in the form of an electronic record by the Compliance Manager. The record should detail the company’s response and any action taken. This should be retained, as with all records of Kuda UK as per our Privacy Policy. The Compliance Department will monitor this and raise any recurring issues for resolution. To ensure learning of all customer related complaint matters, the department responsible for the issue causing a customer to complain will retain responsibility for resolving the matter in conjunction with the Compliance Department.
                                </p>
                                <p className="color-black bottom--spacing">
                                    Any recommendations made after a complaint investigation should be indicated in the Management Information considered by the Board of Kuda UK.
                                </p>
                            </div>
                            <h1 className="kuda-section--heading text-left text-xlbold color-primary title-bottom--spacing">
                                Redress and Compensation
                            </h1>
                            <div className="text-left kuda-disclaimer--wrap">
                                <p className="color-black bottom--spacing">
                                    Kuda UK is committed to ensuring that its customers are treated fairly, and redress and compensation will be offered to a complainant only where it is appropriate to do so.
                                </p>
                                <p className="color-black bottom--spacing">
                                    Redress must only be paid to rectify a detrimental situation which has resulted from the actions of Kuda UK, its employees, agents or subcontractors.o:
                                </p>
                                <p className="color-black bottom--spacing">
                                    The general approach for offering redress is to put the complainant in the position they would have been if the failure had not occurred whether through financial reparation or other suitable alternative actions.
                                </p>
                                <p className="color-black bottom--spacing">
                                    Compensation will be considered where Kuda UK actions have resulted in a customer incurring distress or inconvenience. This redress can be through a financial award or other suitable action, whichever is deemed appropriate based on the individual circumstances.
                                </p>
                                <p className="color-black bottom--spacing">
                                    Where the payment of interest is considered appropriate to be part of any redress or compensation, Kuda UK will apply the accepted FOS rates where applicable:
                                </p>
                                <p className="color-black bottom--spacing">
                                    <ul className="diclaimer-list">
                                        <li>
                                            8% p/a simple from 1st of April 93; or
                                        </li>
                                        <li>
                                            The Bank of England base rate plus 1% compound.
                                        </li>
                                    </ul>
                                </p>
                                <p className="color-black bottom--spacing">
                                    In determining the approach to redress and compensation, we will pay due regard to:
                                </p>
                                <p className="color-black bottom--spacing">
                                    <ul className="diclaimer-list">
                                        <li>
                                            GThe individual circumstances of each complainans.
                                        </li>
                                        <li>
                                            Ensuring any awards are applied consistently an appropriately; and
                                        </li>
                                        <li>
                                            Awards directed by the FOS or other relevant ombudsman schemes.
                                        </li>
                                    </ul>
                                </p>
                            </div>
                            <h1 className="kuda-section--heading text-left text-xlbold color-primary title-bottom--spacing">
                                Internal Reporting and Root Cause Analysis
                            </h1>
                            <div className="text-left kuda-disclaimer--wrap">
                                <p className="color-black bottom--spacing">
                                    Kuda UK will ensure that appropriate systems are maintained for the preparation and dissemination of suitable complaints management information, which will enable the Compliance Manager, senior managers and Board to monitor:
                                </p>
                                <p className="color-black bottom--spacing">
                                    <ul className="diclaimer-list">
                                        <li>
                                            Service Standards;
                                        </li>
                                        <li>
                                            Complaint Volumes;
                                        </li>
                                        <li>
                                            Uphold Rates;
                                        </li>
                                        <li>
                                            FOS Referrals and update rates.
                                        </li>
                                    </ul>
                                </p>
                                <p className="color-black bottom--spacing">
                                    Appropriate management controls will be maintained by Kuda UK to ensure we identify and remedy any recurring or systemic problems. In particular, appropriate processes will be established to:
                                </p>
                                <p className="color-black bottom--spacing">
                                    <ul className="diclaimer-list">
                                        <li>
                                            Analyse the causes of individual complaints so as to identify root causes common to types of complaints;
                                        </li>
                                        <li>
                                            Consider whether such root causes may affect other processes or product; and
                                        </li>
                                        <li>
                                            Correct where reasonable to do so.
                                        </li>
                                    </ul>
                                </p>
                            </div>
                            <h1 className="kuda-section--heading text-left text-xlbold color-primary title-bottom--spacing">
                                Record Keeping
                            </h1>
                            <div className="text-left kuda-disclaimer--wrap">
                                <p className="color-black bottom--spacing">
                                    Kuda UK Records Retention Policy and Privacy Policy sets out the requirements for the robust maintenance of records. Care and due diligence must be taken to ensure all material relating to the resolution of the complaint is retained. The FCA rules require complaint files to be stored for five years (DISP 1.9) from the date that complaint was received. A complaint file must include:
                                </p>
                                <p className="color-black bottom--spacing">
                                    <ul className="diclaimer-list">
                                        <li>
                                            All correspondence to/from the dissatisfied customer in relation to their complaint;
                                        </li>
                                        <li>
                                            All the documents/backup pertinent to the complaint; and
                                        </li>
                                        <li>
                                            The final outcome detailing any compensation paid out.
                                        </li>
                                    </ul>
                                </p>
                                <p className="color-black bottom--spacing">
                                    The file must tell a story whereby someone can pick it up and see the full trail of what was involved and steps taken to resolve the matter - everything must be documented.
                                </p>
                            </div>

                            <h1 className="kuda-section--heading text-left text-xlbold color-primary title-bottom--spacing">
                                Reporting Complaints to the FCA
                            </h1>
                            <div className="text-left kuda-disclaimer--wrap">
                                <p className="color-black bottom--spacing">
                                    Once a year an authorised payment institution such as Kuda UK must provide the FCA with a complete report concerning complaints received about payment services and electronic money.
                                </p>
                                <p className="color-black bottom--spacing">
                                    The complaint report is a standard format prescribed by the FCA and requires the total number of complaints received by each entity broken down into specific categories and generic product types. The returns to the FCA also include information on timescales for complaint resolution, number outstanding, upheld and redress amounts as set out in the FCA handbook under DISP 1 Annex 1AD.
                                </p>
                                <p className="color-black bottom--spacing">
                                    The PS – Complaints Electronic Money and Payment Services Complaints Return (FSA056) report is submitted by the Compliance Manager to the FCA electronically via the FCA RegData reporting system.
                                </p>
                            </div>

                            <h1 className="kuda-section--heading text-left text-xlbold color-primary title-bottom--spacing">
                                Annual Review
                            </h1>
                            <div className="text-left kuda-disclaimer--wrap">
                                <p className="color-black bottom--spacing">
                                    This policy must be reviewed by the Policy Owner every year to ensure its alignment to appropriate legal and regulatory requirements as well as best practice compliance standards.
                                </p>
                                <p className="color-black bottom--spacing">
                                    Every 12 months the Board must issue an up-to-date policy for Kuda UK. Any interim change to this policy must be proposed to the Board/Owners and, if agreed, requires the written approval of members of the Board.
                                </p>
                            </div>
                        </div> */}

                        <div className="kuda-section--100 kuda-spacing--inner">
                            <h1 className="kuda-section--heading text-left text-xlbold color-primary title-bottom--spacing">
                                 A guide to Making a Complaint
                            </h1>
                            <div className="text-left kuda-disclaimer--wrap">
                                <p className="color-black bottom--spacing">
                                    At Kuda UK we aim to provide great products and excellent service to our customers. However, we know that sometimes things can go wrong and that you may have cause to complain. If you have a complaint, then please let us know as soon as possible.
                                </p>
                                <p className="color-black bottom--spacing">
                                    We will do our very best to resolve your complaint as soon as you get in touch with us:
                                </p>
                                <p className="color-black bottom--spacing">
                                    By email: {" "}
                                    <a
                                        href="mailto:complaintuk@kuda.com"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="color-primary text-bold link-decoration cursor-pointer"
                                    >
                                        complaintuk@kuda.com
                                    </a>
                                </p>
                                <p className="color-black bottom--spacing">
                                    By post: Complaint Manager, Kuda EMI Limited, 5 New Street Square, London, United Kingdom, EC4A 3TW
                                </p>
                            </div>
                        </div>

                        <div className="kuda-section--100 kuda-spacing--inner">
                            <h1 className="kuda-section--heading text-left text-xlbold color-primary title-bottom--spacing">
                                Investigating your complaint
                            </h1>
                            <div className="text-left kuda-disclaimer--wrap">
                                <p className="color-black bottom--spacing">
                                    We aim to resolve your complaint within 3 working days following the receipt of your complaint. Once an agreed resolution has been reached, we will confirm details in writing to you in a final resolution letter.
                                </p>
                                <p className="color-black bottom--spacing">
                                    However, there may be occasions when it will take longer to respond to your complaint. If this happens, we will send you an acknowledgement within 5 business days. This acknowledgement will include the name and contact details of the person handling your complaint, this may not be the same person who received your complaint.
                                </p>
                                <p className="color-black bottom--spacing">
                                    In the unlikely event that we have not resolved your complaint within four weeks we will write to you to advise the reason why and when we expect to resolve it. We aim to send you a final response within 35 working days of your original complaint.
                                </p>
                            </div>
                        </div>

                        <div className="kuda-section--100 kuda-spacing--inner">
                            <h1 className="kuda-section--heading text-left text-xlbold color-primary title-bottom--spacing">
                                The Financial Ombudsman Service (FOS)
                            </h1>
                            <div className="text-left kuda-disclaimer--wrap">
                                <p className="color-black bottom--spacing">
                                    The FOS is an independent complaint resolution service that was set up to resolve complaints between financial businesses and their customers.
                                </p>
                                <p className="color-black bottom--spacing">
                                    If you are dissatisfied with our response to your complaint, you have the right to contact the FOS, free of charge, but you must do so within six months of the date of our final response letter. If you do not refer your complaint in time, the FOS will not have our permission to consider your complaint and so will only be able to do so in very limited circumstances.
                                </p>
                                <p className="color-black bottom--spacing">
                                    The FOS contact details are:
                                </p>
                                <p className="color-black bottom--spacing">
                                    By telephone: Freephone number is 0800 023 4567 or 0300 123 9123 from a mobile
                                </p>
                                <p className="color-black bottom--spacing">
                                    By email: {" "}
                                    <a
                                        href="mailto:complaint.info@financial-ombudsman.org.uk"
                                        target="_blank"
                                        rel="nofollow noopener noreferrer"
                                        className="color-primary text-bold link-decoration cursor-pointer"
                                    >
                                        complaint.info@financial-ombudsman.org.uk
                                    </a>
                                </p>
                                <p className="color-black bottom--spacing">
                                    By Post: The Financial Ombudsman Service
                                </p>
                                <p className="color-black bottom--spacing">
                                    Exchange Tower
                                </p>
                                <p className="color-black bottom--spacing">
                                    London
                                </p>
                                <p className="color-black bottom--spacing">
                                    E14 9SR
                                </p>
                                <p className="color-black bottom--spacing">
                                    Visit their website: {" "}
                                    <a
                                        href="https://www.financial-ombudsman.org.uk/"
                                        target="_blank"
                                        rel="nofollow noopener noreferrer"
                                        className="color-primary text-bold link-decoration cursor-pointer"
                                    >
                                        https://www.financial-ombudsman.org.uk
                                    </a>
                                </p>
                                <p className="color-black bottom--spacing">
                                    You must refer your complaint to the Financial Ombudsman within 6 months of the date on the final response.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CTAUk />
        </Fragment>
    )
}

export default Complaints
